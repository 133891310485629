<template>
  <router-link :to="linkTo" class="main-card">
    <img
      class="main-card__image"
      :src="bgImage(data)"
      alt="preview"
    />
    <div class="main-card__game">
      <div v-if="data?.games?.length">
        <game-tag v-for="tag in data.games" :id="tag" :key="tag" />
      </div>
      <game-tag v-else :id="data.idGame" />
    </div>
    <h3 class="main-card__title">
      {{ data.name }}
    </h3>
    <div class="main-card__date">
      {{ getDate(data.tsPublishTime) }}
    </div>
  </router-link>
</template>

<script>
import { getDateString } from '@utils/time.js';

export default {
  name: 'MainCard',
  props: {
    data: {
      type: Object,
      required: true,
    },
    linkTo: {
      type: String,
      required: true,
    },
  },

  methods: {
    getDate(seconds) {
      return getDateString(seconds, 'ru');
    },
    defaultImage(size) {
      const baseUrl = process.env.VUE_APP_BASE_URL.replace(/\/$/, '');
      return `${baseUrl}/img/games/default/${size}.jpg`;
    },
    bgImage(data) {
      return (
        data?.imgPreview ||
        data?.urlPreview ||
        this.defaultImage('small')
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  display: block;
  color: #f3f3f3;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
}

.main-card__game,
.main-card__title {
  margin-bottom: 8px;
}

.main-card__image {
  display: block;
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  margin-bottom: 12px;
  border: 1px solid #2b2b2b;
  border-radius: 8px;
}

.main-card__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.main-card__date {
  color: #979797;
  font-size: 12px;
  line-height: 16px;
}
</style>
